<template>
  <div id="index">
    <dv-full-screen-container class="bg"
                              v-if="projectId==null">
      <div class="loginBox">
        <p class="loginBox-title">智能跑道登录</p>
        <a-form :model="formState"
                name="basic"
                :label-col="{ span: 7 }"
                :wrapper-col="{ span: 12 }"
                autocomplete="off"
                @finish="onFinish"
                @finishFailed="onFinishFailed">
          <a-form-item label="账号"
                       name="padUsername"
                       :rules="[{ required: true, message: 'Please input your padUsername!' }]">
            <a-input allowClear
                     v-model="formState.padUsername" />
          </a-form-item>

          <a-form-item label="密码"
                       name="padPassword"
                       :rules="[{ required: true, message: 'Please input your padPassword!' }]">
            <a-input-password v-model="formState.padPassword" />
          </a-form-item>

          <a-form-item :wrapper-col="{ offset: 11, span: 6 }">
            <a-button type="primary"
                      html-type="submit"
                      @click="onFinish">登录</a-button>
          </a-form-item>
        </a-form>
      </div>
    </dv-full-screen-container>

    <dv-full-screen-container class="bg"
                              v-else>
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else
           class="host-body">
        <!-- <div class="d-flex jc-center"> -->
        <!-- <dv-decoration-10 style="width:33.3%;height:.0625rem;"/> -->
        <div class="d-flex topDiv">
          <div class="d-flex"
               style="width:30%">
            <div class="d-flex">
              <span class="topDivText">当前在跑人数:</span>
              <span class="topDivTotal">{{indexInfo.running_user}}</span>
            </div>
            <div class="d-flex"
                 style='margin-left:0.46rem'>
              <span class="topDivText">总人数:</span>
              <span class="topDivTotal">{{indexInfo.total_user}}</span>
            </div>
          </div>

          <div class="mainTitle" ref="myButton" @click="alertMessage">{{indexInfo.title}}</div>

          <div style="min-width:30%">
            <span class="dataText">{{ dateYear }}</span>
            <span class="dataText">{{ dateDay }}</span>
            <span class="dataText">{{ dateWeek }}</span>
            <span class="dataText">{{weather}}</span>
            <span class="dataText">{{temp}}</span>
            <span class="dataText">湿度: {{humidity}}</span>
          </div>
        </div>
        <!-- <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);"/> -->
        <!-- </div> -->

        <div class="body-box">
          <div class="body-mainBox">
            <div class="body-leftBox">
              <!-- 数据汇总 -->
              <div class="body-left-topBox">
                <div class="body-left-top1Box">
                  <div class="body-left-top-li">
                    <div class="body-left-top-li-totalBox">
                      <div class="body-left-top-li-total">{{indexInfo.total_user}}</div>
                      <!-- <img class="body-left-top-li-img" src="../static/down.png"> -->
                    </div>
                    <div class="body-left-top-li-textBox">
                      <div class="body-left-top-li-textR"></div>
                      <div class="body-left-top-li-text">总用户 (人)</div>
                    </div>
                  </div>

                  <div class="body-left-top-li">
                    <div class="body-left-top-li-totalBox">
                      <div class="body-left-top-li-total">{{indexInfo.day_user}}</div>
                      <!-- <img class="body-left-top-li-img" src="../static/down.png"> -->
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.day_user_trend==-1"
                           src="../static/down.png">
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.day_user_trend==1"
                           src="../static/upward.png">
                    </div>
                    <div class="body-left-top-li-textBox">
                      <div class="body-left-top-li-textR"></div>
                      <div class="body-left-top-li-text">今日运动 (人)</div>
                    </div>
                  </div>
                </div>

                <div class="body-left-top1Box">
                  <div class="body-left-top-li">
                    <div class="body-left-top-li-totalBox">
                      <div class="body-left-top-li-total">{{indexInfo.avg_duration}}</div>
                      <!-- <img class="body-left-top-li-img" src="../static/upward.png"> -->
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.avg_duration_trend==-1"
                           src="../static/down.png">
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.avg_duration_trend==1"
                           src="../static/upward.png">
                    </div>
                    <div class="body-left-top-li-textBox">
                      <div class="body-left-top-li-textR"></div>
                      <div class="body-left-top-li-text">人均时长 (分)</div>
                    </div>
                  </div>

                  <div class="body-left-top-li">
                    <div class="body-left-top-li-totalBox">
                      <div class="body-left-top-li-total">{{indexInfo.avg_distance}}</div>
                      <!-- <img class="body-left-top-li-img" src="../static/upward.png"> -->
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.avg_distance_trend==-1"
                           src="../static/down.png">
                      <img class="body-left-top-li-img"
                           v-if="indexInfo.avg_distance_trend==1"
                           src="../static/upward.png">
                    </div>
                    <div class="body-left-top-li-textBox">
                      <div class="body-left-top-li-textR"></div>
                      <div class="body-left-top-li-text">人均距离 (米)</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="body-left-bottomBox">
                <adPage :indexInfo='indexInfo' />
              </div>
            </div>
            <!-- 中间一列 -->
            <div class="body-centerBox">
              <div class="body-centerBox-topBox">
                <div class="body-centerBox-topBox-mapBox">
                  <img class="body-centerBox-topBox-map"
                       src="../static/sportMap.png">
                  <div class="body-centerBox-topBox-mapTitle">跑道地图</div>

                  <div class="userPoint-Box1">
                    <img class="userPoint-img"
                         src="../static/userPoint1.png"
                         alt="">
                    <span class="userPoint-text">软萌妹子</span>
                  </div>
                  <div class="userPoint-Box2">
                    <img class="userPoint-img"
                         src="../static/userPoint2.png"
                         alt="">
                    <span class="userPoint-text">帅哥1号</span>
                  </div>
                  <div class="userPoint-Box3">
                    <img class="userPoint-img"
                         src="../static/userPoint1.png"
                         alt="">
                    <span class="userPoint-text">软萌妹子</span>
                  </div>
                  <div class="userPoint-Box4">
                    <img class="userPoint-img"
                         src="../static/userPoint2.png"
                         alt="">
                    <span class="userPoint-text">帅哥2号</span>
                  </div>

                  <div class="equipment1">
                    <img class="equipment-img"
                         src="../static/equipment.png"
                         alt="">
                    <p class="equipment-text">一体机</p>
                  </div>

                  <div class="equipment2">
                    <div class="equipmentBox">
                      <!-- <img class="equipment-img" src="../static/equipment1.png" alt=""> -->
                      <p class="equipment-text">监测站1</p>
                    </div>

                  </div>
                </div>
              </div>

              <div class="body-centerBox-bottomBox">
                <div class="body-centerBox-bottomBox-leftBox">
                  <dayRankList :dallData.sync='dallData' />
                  <div class="centerBox-bottomBox-leftBox-title">今日排行</div>
                </div>

                <div class="body-centerBox-bottomBox-rightBox">
                  <historyRankList :allData.sync='allData' />
                  <div class="centerBox-bottomBox-rightBox-title">总榜排行</div>
                </div>
              </div>
            </div>
            <!-- 第三列 -->
            <div class="body-rightBox">
              <div :class="this.classChane">
                <div class="body-rightBox-topBox-top">
                  <div class="registerBox"
                       @click="getRegister">
                    <span class="register">注册</span>
                  </div>
                  <div class="loginBox"
                       @click="getLogin">
                    <span class="login">登录</span>
                  </div>
                </div>

                <div class="body-rightBox-centerBox">
                  <div class="rightBox-centerBox-borderBox">
                    <img class="rightBox-centerBox-border-img"
                         src="../static/loginBorder.png">
                    <!-- <div class="rightBox-centerBox-borderBox-centerBox"></div> -->
                    <img class="rightBox-centerBox-border-userImg"
                         src="../static/userImg.jpg">
                  </div>
                </div>

                <div class="rightBox-centerBox-bottomBox">
                  <button class="register-button"
                          @click="showloginModal">{{this.classChane=='body-rightBox-topBox'?'立即注册':'立即登录'}}</button>
                  <!-- <button class="grade-button" @click="showCodeModal">查看成绩</button> -->
                </div>

                <a-modal v-model="loginVisible"
                         :width="700"
                         :closable="false"
                         :footer="null"
                         @cancel="closeLoginModal"
                         style="margin: -10px auto;">
                  <dv-border-box-11 title="登录"
                                    style="width:650px;height: 530px">

                    <div style="width:100%;height: 580px;">
                      <div v-show="step==1"
                           style="position:relative">
                        <video ref="video"
                               style="width:100%;height:100%;min-height:5.125rem;position:absolute;transform: translate(0%, 30%);"
                               id="video"
                               @loadedmetadata="fnRun"
                               muted="muted"
                               loop="loop"
                               playsinline=""></video>
                        <canvas ref="canvas"
                                style="display:none;width:100%;height:100%;min-height:5.125rem;"></canvas>
                        <!--                              <img class="-align-center" src="../assets/faceback.png"-->
                        <!--                                   style="left:2.5rem;max-height:4.125rem;position:absolute ">-->
                        <!--                      <div style="height: 4.125rem;width:4.75rem;position:absolute;text-align: center  ">正在识别人脸</div>-->

                        />
                      </div>
                      <div v-show="step==2"
                           style="position:relative;top: 10%">
                        <div>

                          <a-row type="flex"
                                 justify="center"
                                 style="padding: 0.3rem"
                                 align="top"
                                 :gutter="[16,16]">
                            <!--                                    <a-spin tip="正在测量身高体重..." size="large">-->
                            <a-col :span="12">

                              <a-result status="success"
                                        :title="'身高测量结果：'+(weightHeight!=null?weightHeight.height:'--.-')+'cm'"
                                        sub-title="">

                                <template #icon
                                          v-if="!scale">
                                  <a-icon type="loading" />
                                </template>
                              </a-result>

                            </a-col>
                            <a-col :span="12">
                              <a-result status="success"
                                        :title="'体重测量结果：'+(weightHeight!=null?weightHeight.weight:'--.-')+'kg'"
                                        sub-title="">
                                <template #icon
                                          v-if="!scale">
                                  <a-icon type="loading" />
                                </template>
                              </a-result>
                            </a-col>
                            <!--                                    </a-spin>-->
                          </a-row>

                        </div>
                        <div v-if="!scale"
                             style="padding-left: 50px;padding-right: 50px;width:100%;">
                          <!--                            <p class="titlefont">1:请站在体重秤上，保持立正姿势</p>-->
                          <!--                            <p class="titlefont">2:请勿多人同时测量</p>-->
                          <a-alert message="测量说明"
                                   description="请站在体重秤上，保持立正姿势"
                                   type="success"
                                   show-icon />
                        </div>
                        <a-result v-if="scale"
                                  style="">
                          <template #icon>
                            <img v-if="codeImage"
                                 class="-align-center"
                                 style="width: 30%;height: 20%;margin-bottom: -12px"
                                 :src="'data:image/jpg;base64,'+codeImage" />
                            <img v-else
                                 class="-align-center"
                                 style="width: 30%;height: 20%;margin-bottom: -12px"
                                 src="../static/code.png" />
                          </template>
                        </a-result>

                      </div>
                      <div v-show="step==4"
                           style="position:relative">
                        <a-result title="登录成功，请开始跑步吧!"
                                  style="padding-top: 1rem;padding-bottom: 0.45rem">
                          <template #icon>
                            <img class="-align-center"
                                 style="width: 300px;height: 300px;"
                                 src="../static/sport.png" />
                          </template>
                        </a-result>
                      </div>
                      <div v-show="step==5"
                           style="position:relative">
                        <a-result title="登录成功，请开始跑步吧!"
                                  style="padding-top: 1rem;padding-bottom: 0.45rem">
                          <template #icon>
                            <img class="-align-center"
                                 style="width: 300px;height: 300px;"
                                 src="../static/sport.png" />
                          </template>
                        </a-result>
                      </div>
                    </div>
                    <div class="ant-result-extra"
                         style="margin-top: -120px">
                      <a-button ghost
                                v-if="isConfirm==true"
                                @click="loginSuccess">
                        确定
                      </a-button>
                      <a-button v-else
                                ghost
                                @click="closeLoginModal">
                        关闭
                      </a-button>
                      <div v-if="isConfirm!=true"
                           style="font-size:0.25rem;position:absolute;width:50px;height:50px;left: 30px;bottom:27px;color: #c3cbde">
                        {{ timerCount }}
                      </div>
                    </div>
                  </dv-border-box-11>
                </a-modal>

                <a-modal v-model="codeVisible"
                         :closable="false"
                         :footer="null"
                         @cancel="closeCodeModal">
                  <dv-border-box-11 title="查看成绩">

                    <a-result title="用微信扫描二维码查看"
                              style="padding-top: 0.9rem;padding-bottom: 0.45rem">
                      <template #icon>
                        <img class="-align-center"
                             style="width: 300px;height: 300px;"
                             src="../static/code.jpg" />
                      </template>
                      <template #extra>
                        <a-button ghost
                                  @click="closeCodeModal">
                          关闭
                        </a-button>
                        <div style="font-size:0.25rem;position:absolute;width:50px;height:50px;left: 30px;bottom:20px;color: #c3cbde">
                          {{ timerCount }}
                        </div>
                      </template>
                    </a-result>
                  </dv-border-box-11>
                </a-modal>
              </div>

              <div class="body-rightBox-bottomBox">
                <div class="rightBox-bottomBox-topBox">
                  <div class="rightBox-bottomBox-topBox-li">1,首次使用请点击“立即注册”,进行人脸识别注册</div>
                  <div class="rightBox-bottomBox-topBox-li">2,站立在测重区域,抬头挺胸,目视前方摄像头,进行人脸识别并记录身高体重</div>
                  <div class="rightBox-bottomBox-topBox-li">3,注册成功,打开微信扫描二维码同步仕雅智跑小程序,可查看运动数据并制定运动计划,分享智跑成绩</div>
                  <div class="rightBox-bottomBox-topBox-li">4、您可以开始沿着跑道进行运动啦，沿途有AI运动监测站点自动监测运动数据，跑步结束可在大屏端和小程序查看跑步成绩单。</div>
                </div>

                <div class="rightBox-bottomBox-bottomBox">
                  <div class="rightBox-bottomBox-bottomBox-img">
                    <!-- <img class="program-img" v-if="codeImage" :src="'data:image/jpg;base64,'+codeImage" alt=""> -->
                    <img class="program-img"
                         src="../static/code.png"
                         alt="">
                    <div class="prompt-text">扫码进入小程序</div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import { formatTime } from '../utils/index.js'
// import dataTotal from "./dataTotal";
// import mainPage from "./mainPage";

import dayRankList from "./dayRankList";
import historyRankList from "./historyRankList";
// import centerRight2 from "./centerRight2";
import adPage from "./adPage";

// import companyInfo from "./companyInfo";
import axios from 'axios'
import * as faceapi from "face-api.js";

export default {
  data () {
    return {
      loading: true,
      weatherStr: null,
      sportcount: 0,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      classChane: 'body-rightBox-topBox',
      step: 0,
      codeVisible: false,
      loginVisible: false,
      weightHeight: null,
      timerCount: 0,
      sportUser: null,
      indexData: '',
      indexInfo: '',
      dayRank: [],
      sumRank: [],
      codeImage: null,
      allData: [],
      newData: [],
      indexNum: 0,
      dallData: [],
      dnewData: [],
      dindexNum: 0,
      weather: '',
      humidity: '',
      temp: '',
      isConfirm: false,
      // 项目id
      projectId: null,
      // projectId: 'kyDySTT1uC1N766r',
      formState: {
        padUsername: '',
        padPassword: ''
      },
      scale: false,
      nets: "tinyFaceDetector", // 模型
      options: null, // 模型参数
      detectFace: "detectSingleFace", // 单or多人脸
      cameraTimer: null,
      hRankList: null,
      dRankList: null,
      constraints: {
        audio: false,
        video: {
          // ideal（应用最理想的）
          width: {
            min: 320,
            ideal: 1280,
            max: 1920,
          },
          height: {
            min: 240,
            ideal: 720,
            max: 1080,
          },
          // frameRate受限带宽传输时，低帧率可能更适宜
          frameRate: {
            min: 15,
            ideal: 30,
            max: 60,
          },
          // 显示模式前置后置
          facingMode: "environment",
        }
      }
    };
  },
  components: {
    dayRankList,
    // dataTotal,
    // mainPage,

    historyRankList,
    // centerRight2,
    adPage,

    // companyInfo
  },

  created () {
    let projectId = localStorage.getItem("projectId");
    console.log(projectId);
    this.projectId = projectId
  },

  mounted () {
    setTimeout(() => {
      this.$refs.myButton.click();
    }, 60000)

    this.getIndexInfo()
    this.timeFn();
    setTimeout(() => {
      this.getRankList()
    }, 1200)
    setTimeout(() => {
      this.getDayRank()
    }, 1200)

    // this.throb();
    // setInterval(this.throb, 1000*60);
    this.cancelLoading();
    // this.getWeather();
    // this.getWeatherTimer();

    this.$nextTick(() => {
      this.messageConfig();
      this.fnInit();
      this.getIndexInfoTimer()
    });
  },
  methods: {
    alertMessage() {
      console.log(111);
    },

    onFinish () {
      console.log(this.formState);

      axios
        .post('https://sport.app.api.whsdzn.com/local/user/login', {
          "padUsername": this.formState.padUsername,
          "padPassword": this.formState.padPassword,
        })
        .then(res => {
          if (res.data.code == 200) {
            localStorage.setItem("projectId", res.data.data.id);
            this.projectId = res.data.data.id
            // console.log(res.data.data.id);
            // location.reload() 
            this.getIndexInfo();
            setTimeout(() => {
              this.getRankList()
            }, 1000)
            setTimeout(() => {
              this.getDayRank()
            }, 1000)
            this.$message.info(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        })
    },
    onFinishFailed (values) {
      console.log(values);
    },
    getRegister () {
      this.classChane = 'body-rightBox-topBox'
    },
    getLogin () {
      this.classChane = 'body-rightBox-topBox2'
    },
    setdTabledata () {
      this.dnewData.shift();
      this.dnewData.push(this.dallData[this.dindexNum])
      if (this.dindexNum + 1 < this.dallData.length) {
        this.dindexNum = this.dindexNum + 1
      } else {
        this.dindexNum = 0
      }
    },
    setTabledata () {
      this.newData.shift();
      this.newData.push(this.allData[this.indexNum])
      if (this.indexNum + 1 < this.allData.length) {
        this.indexNum = this.indexNum + 1
      } else {
        this.indexNum = 0
      }
    },
    getRankList () {
      if (this.sumRank) {
        this.allData = this.sumRank.map((v) => {
          let num = (v.sumDistance / this.sumRank[0].sumDistance).toFixed(2) * 100
          return {
            ...v,
            num: `${num}%`
          }
        })

        // this.hRankList = setInterval(() => {
        //   if (this.allData.length > 6) {
        //     this.setTabledata()
        //   }
        // }, 5000)
        // this.allData.map((v, i) => {
        //   if (i < 7) {
        //     this.indexNum = i + 1
        //     this.newData.push({
        //       ...v,
        //     })
        //   }
        // })
      }
    },
    getDayRank () {
      if (this.dayRank) {
        this.dallData = this.dayRank.map((v) => {
          let num = (v.distance / this.dayRank[0].distance).toFixed(2) * 100
          return {
            ...v,
            num: `${num}%`
          }
        })

        // this.dRankList = setInterval(() => {
        //   if (this.dallData.length > 6) {
        //     this.setdTabledata()
        //   }
        // }, 5000)
        // this.dallData.map((v, i) => {
        //   if (i < 7) {
        //     this.dindexNum = i + 1
        //     this.dnewData.push({
        //       ...v,
        //     })
        //   }
        // })
      }
    },
    getIndexInfo () {
      axios
        .get(`https://sport.app.api.whsdzn.com/pad/index/info?projectId=${this.projectId}`)
        .then(result => {
          this.indexInfo = result.data.data.info,
            this.dayRank = result.data.data.dayRank
          this.sumRank = result.data.data.sumRank
          this.getWeather()
            // this.getWeatherTimer()
        })
    },

    getIndexInfoTimer () {
      setInterval(() => {
        this.getIndexInfo();
        this.newData.splice(0, this.newData.length)
        this.dnewData.splice(0, this.dnewData.length)
        clearInterval(this.hRankList)
        clearInterval(this.dRankList)
        this.getRankList()
        this.getDayRank()
      }, 15000)
    },

    timeFn () {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000)

    },
    // async throb() {
    //   axios
    //       .get('/v1/api/screen/throb')
    //       .then(response => (
    //           console.log("心跳"+response)
    //       ))
    // },
    async getWeather () {
      axios
        .get(`https://sport.app.api.whsdzn.com/pad/index/getWeatherByCity`, {
          params: {
            citycode: this.indexInfo.citycode,
            projectId: this.projectId
          }
        })
        .then(response => (
          //console.log(response.data)
          this.getWeatherResult(response.data)
          // if (response.status == 200) {
          //   this.weatherStr = response.data.data.descn;
          // }
        ))
    },
    getWeatherResult (data) {
      this.weather = data.data.weather;
      this.temp = data.data.temp;
      this.humidity = data.data.humidity;
      // this.sportcount=data.sportcount;
    },
    getWeatherTimer () {
      setInterval(() => {
        this.getWeather();
      }, 5 * 1000 * 60)
    },
    cancelLoading () {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    messageConfig () {
      this.$message.config({
        top: `100px`,
        duration: 2,
        maxCount: 1,
      });
    },
    async fnInit () {
      try {

        //运行代码
        await faceapi.nets[this.nets].loadFromUri("../models");
        await faceapi.loadFaceLandmarkModel("../models");

      } catch (err) {
        //处理错误
        // this.$message.info(err);

      }

      // 根据模型参数识别调整结果
      switch (this.nets) {
        case "ssdMobilenetv1":
          this.options = new faceapi.SsdMobilenetv1Options({
            minConfidence: 0.5, // 0.1 ~ 0.9
          });
          break;
        case "tinyFaceDetector":
          this.options = new faceapi.TinyFaceDetectorOptions({
            inputSize: 320, // 160 224 320 416 512 608
            scoreThreshold: 0.5, // 0.1 ~ 0.9
          });
          break;
        case "mtcnn":
          this.options = new faceapi.MtcnnOptions({
            minFaceSize: 20, // 0.1 ~ 0.9
            scaleFactor: 0.709, // 0.1 ~ 0.9
          });
          break;
      }
      console.log("人脸识别模型初始化成功.....")
    },
    fnOpen () {
      console.log("打开摄像头")
      if (typeof window.stream === "object") return;
      this.step = 1;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(this.fnSuccess)
          .catch(this.fnError);
      }, 300);
    },
    // 成功启动视频媒体流
    fnSuccess (stream) {
      if (null == this.video) {
        this.video = this.$refs.video;
        console.log("初始化摄像机组件")
      }
      window.stream = stream; // 使流对浏览器控制台可用
      console.log("启动摄像机开始")
      this.video.srcObject = stream;
      this.video.play();
      console.log("启动摄像机结束")
      // this.cameraTimer=setInterval(() => {
      //     this.fnRun();
      // }, 1000 * 10)
    },
    //拍照
    async fnRun () {
      console.log("识别人脸" + new Date())
      const result = await faceapi[this.detectFace](this.video, this.options);
      /**
       * 检测到人脸，将数据上传到平台
       */
      if (result && !this.video.paused) {
        this.canvas = this.$refs.canvas;
        this.canvas.width = this.video.videoWidth;
        this.canvas.height = this.video.videoHeight;
        let thisContext = this.canvas.getContext("2d");
        console.log("video width:" + this.video.videoWidth + "video heigth" + this.video.videoHeight)
        console.log("canvas width:" + this.canvas.width + "canvas heigth" + this.canvas.height)
        thisContext.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight);
        // 获取图片base64链接
        let image = this.canvas.toDataURL("image/jpeg");
        console.log("生成base64成功");
        this.postImage(image);
        this.$message.destroy();
      } else {
        console.log("无法检测到人脸");
        this.$message.info('正在识别人脸,请正对摄像头');

      }
      this.timeout = setTimeout(() => this.fnRun(), 2000);
    },
    getWeightAndHeight () {
      console.log("测量身高体重");
      axios
        .post('/v1/api/user/getWeightAndHeight?projectId=' + this.projectId)
        .then(response => (
          this.getWeightAndHeightResult(response.data)
        ))
    },
    getWeightAndHeightResult (data) {
      if (data.code == 200) {
        //测量成功
        this.scale = true;
        this.weightHeight = data.data;
        console.log(this.sportUser);
        if (this.sportUser.id != "") {
          this.getCodeImage();
          this.isConfirm = true
          this.timerCount = 300
          //提交登录信息
          axios
            .post('https://sport.app.api.whsdzn.com/pad/index/saveUserData', {
              "face": this.sportUser.face,
              "height": this.weightHeight.height,
              "weight": this.weightHeight.weight,
              "heartRate": 75,
              "projectId": this.projectId
            })
            .then(response => (
              console.log(response)
            ))
          // setTimeout(()=>{
          //   this.loginSuccess();
          // },10000)

          //用户登录
        } else {
          // this.getCodeImage();
        }

      } else {
        this.$message.error(data.message);
      }
    },
    /**
     * 获取动态绑定二维码带人脸
     */
    getCodeImage () {
      console.log("生成动态二维码");
      axios
        .get('https://sport.app.api.whsdzn.com/pad/index/getCodeImgByte', {
          params: {
            face: this.sportUser.face,
            projectId: this.projectId
          }
        })
        .then(response => (
          this.getCodeImageResult(response.data)
        ))
    },
    getCodeImageResult (data) {
      console.log(data);
      this.codeImage = data.data;
      // if (this.step > 0 && data.success) {
      //   console.log(data)
      //   this.codeImage = data.data;
      //   clearTimeout(this.timeout);
      //   this.timeout = setTimeout(() => this.getSportUser(), 1000);
      // }
    },
    postImage (bs) {

      axios
        .post('/v1/api/user/uploaFace', { "data": bs, projectId: this.projectId })
        .then(response => (
          this.scanResult(response.data)
        ))
    },
    getSportUser () {
      console.log("查询当前二维码是否被微信扫码綁定");
      axios
        .get('/v1/api/user/getSportUserByFace?face=' + this.sportUser.face)
        .then(response => (
          this.getSportUserResult(response.data)
        ))
    },
    getSportUserResult (data) {
      console.log(data);
      if (this.step > 0) {
        if (data.success) {
          this.sportUser = data.result
          //提交用户登录信息
          this.loginSuccess();
        } else {
          //自动刷新页面
          this.timeout = setTimeout(() => this.getSportUser(), 1000);
        }
      }
    },
    loginSuccess () {
      this.step = 4;
      this.timerCount = 5;
      this.isConfirm = false
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.closeLoginModal(), 4000);
      // //提交登录信息
      // axios
      //     .post('https://sport.app.api.whsdzn.com/pad/index/saveUserData', {
      //       "face": this.sportUser.face,
      //       "height": this.weightHeight.height,
      //       "weight": this.weightHeight.weight,
      //       "heartRate": 75,
      //       "projectId":this.projectId
      //     })
      //     .then(response => (
      //         console.log(response)
      //     ))
    },
    scanResult (data) {
      console.log(data);
      // if (this.step > 0 && data.success) {
      //   this.fnClose();
      //   this.step = 2;
      //   this.timerCount=60;
      //   this.sportUser = data.result
      //   this.getWeightAndHeight();
      // }

      if (data.code == 200) {
        this.fnClose();
        this.step = 2;
        this.timerCount = 60;
        this.sportUser = data.data
        this.getWeightAndHeight();
      }
    },
    // 失败启动视频媒体流
    fnError (error) {
      this.step = 0;
      console.log(error);
      alert("视频媒体流获取错误" + error);
    },
    // 结束摄像头视频媒体
    fnClose () {
      clearTimeout(this.timeout);
      if (null != this.video) {
        this.video.pause();
        if (typeof window.stream === "object") {
          window.stream.getTracks().forEach((track) => track.stop());
          window.stream = "";
          this.video.srcObject = null;
        }
      }
    },
    showModal () {
      this.visible = true;
    },
    closeCodeModal () {
      this.codeVisible = false;
      console.log("点击关闭");
      clearInterval(this.cameraTimer)
      clearTimeout(this.timeout);
      this.$bus.$emit('playVideo', true)
    },
    showCodeModal () {
      this.codeVisible = true;
      this.startTimerCount();
      this.$bus.$emit('playVideo', false)
    },
    closeLoginModal () {
      console.log("点击关闭");
      this.$message.destroy();
      this.scale = false;
      this.sportUser = null;
      this.weightHeight = null;
      this.step = 1;
      //销毁拍照
      this.fnClose();
      this.loginVisible = false;
      clearTimeout(this.timeout);
      clearInterval(this.cameraTimer)
      this.$bus.$emit('playVideo', true)

    },
    showloginModal () {
      this.sportUser = null;
      this.step = 1;
      this.loginVisible = true;
      this.fnOpen();
      this.startTimerCount();
      this.$bus.$emit('playVideo', false)

    },
    startTimerCount () {
      this.timerCount = 60;
      this.cameraTimer = setInterval(() => {
        this.timerCount--
        if (this.timerCount === 0) {
          if (this.loginVisible) {
            this.closeLoginModal();
          }
          if (this.codeVisible) {
            this.closeCodeModal();
          }
          clearInterval(this.cameraTimer)
        }
      }, 1000)
    }
  },
  beforeDestroy () {
    this.fnClose();
    clearInterval(this.hRankList)
  }
};
</script>

<style lang="scss">
@import "../static/scss/index.scss";
.fs-xl {
  font-size: 0.15rem;
}
.project-list {
  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .datetime {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }
}

.item-group {
  padding: 20px 0 8px 24px;
  font-size: 0;

  a {
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    font-size: 14px;
    margin-bottom: 13px;
    width: 25%;
  }
}

.members {
  a {
    display: block;
    margin: 12px 0;
    line-height: 24px;
    height: 24px;

    .member {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 24px;
      max-width: 100px;
      vertical-align: top;
      margin-left: 12px;
      transition: all 0.3s;
      display: inline-block;
    }

    &:hover {
      span {
        color: #1890ff;
      }
    }
  }
}

.mobile {
  .project-list {
    .project-card-grid {
      width: 100%;
    }
  }

  .more-info {
    border: 0;
    padding-top: 16px;
    margin: 16px 0 16px;
  }

  .headerContent .title .welcome-text {
    display: none;
  }
}

.title {
  font-size: 0.3rem;
  background-position: 0 0;
}

.titlefont {
  font-size: 0.2rem;
  color: #d3d6dd;
}

.group {
  font-size: 0.3rem;
  background-position: 0 -22px;
}

.address {
  font-size: 0.3rem;
  background-position: 0 -44px;
}

.height-100 {
  height: 1rem;
}

.ant-result-title {
  color: #d3d6dd;
}

.ant-modal-header {
  padding: 0px;
  color: #d3d6dd;
  display: none;
  background-color: rgb(12 16 30);
}

.ant-modal-title {
  color: #d3d6dd;
}

.ant-modal-content {
  background-color: #080a14;
}

.ant-message .anticon {
  font-size: 30px;
}

.ant-message {
  font-size: 24px;
}

.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}

.ant-spin {
  font-size: 24px;
  color: #1890ff;
}

.ant-alert-success {
  background-color: rgba(0, 0, 0, 0.07);
  border: 1px solid #7895e6;
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgb(255 255 255 / 85%);
  font-size: 20px;
}

.ant-alert-description {
  display: block;
  margin-bottom: 4px;
  color: rgb(255 255 255 / 85%);
  font-size: 20px;
}

.ant-result {
  padding: 0px 0px;
}
</style>
