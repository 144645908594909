<template>
  <div id="centerRight1">
    <!-- <div class="centerBox"> -->
      <!-- <div class="centerBox-ul"
           v-for="(item, index) in newData"
           :key="index">
        <div class="centerBox-li">
          <div class="centerBox-li1">
            <img class="centerBox-li1-img"
                 v-if="item.rank==1"
                 src="../static/NO1.png"
                 alt="">
            <img class="centerBox-li1-img"
                 v-if="item.rank==2"
                 src="../static/NO2.png"
                 alt="">
            <img class="centerBox-li1-img"
                 v-if="item.rank==3"
                 src="../static/NO3.png"
                 alt="">
            <div class="centerBox-li1-no">{{item.rank==1?'':item.rank==2?'':item.rank==3?'':item.rank}}</div>
          </div>
          <div class="centerBox-li2">{{item.nickName?item.nickName:''}}</div>

          <div class="centerBox-li3">
            <div :style={width:item.num}
                 class="centerBox-li3-1"
                 v-if="item.rank%2==1">{{item.sumDistance}}
            </div>
            <div :style={width:item.num}
                 class="centerBox-li3-2"
                 v-if="item.rank%2==0">{{item.sumDistance}}
            </div>
            <span class="centerBox-li3-time">{{item.sumTime}}min</span>
          </div>

        </div>
      </div> -->

      <vue-seamless-scroll :data="allData"
                           :class-option="classOption"
                           class="centerBox"
                           >
        <div class="centerBox-ul"
             v-for="(item, index) in allData"
             :key="index">
          <div class="centerBox-li">
            <div class="centerBox-li1">
              <img class="centerBox-li1-img"
                   v-if="item.rank==1"
                   src="../static/NO1.png"
                   alt="">
              <img class="centerBox-li1-img"
                   v-if="item.rank==2"
                   src="../static/NO2.png"
                   alt="">
              <img class="centerBox-li1-img"
                   v-if="item.rank==3"
                   src="../static/NO3.png"
                   alt="">
              <div class="centerBox-li1-no">{{item.rank==1?'':item.rank==2?'':item.rank==3?'':item.rank}}</div>
            </div>
            <div class="centerBox-li2">{{item.nickName?item.nickName:''}}</div>

            <div class="centerBox-li3">
              <div :style={width:item.num}
                   class="centerBox-li3-1"
                   v-if="item.rank%2==1">{{item.sumDistance}}
              </div>
              <div :style={width:item.num}
                   class="centerBox-li3-2"
                   v-if="item.rank%2==0">{{item.sumDistance}}
              </div>
              <span class="centerBox-li3-time">{{item.sumTime}}min</span>
            </div>

          </div>
        </div>
      </vue-seamless-scroll>
    <!-- </div> -->
  </div>
</template>

<script>
// import axios from "axios";
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'historyRankList',
  components: { vueSeamlessScroll },
  props: {
    allData: Array
  },
  data () {
    return {
      classOption: {
          step: 0.2,
          hoverStop: false,
          limitMoveNum:7
          // singleHeight: 80,
          // waitTime:3000
        },
      tabledata: [
        {
          rank: "1",
          name: "张三",
          class: 'background-color:#fff',
          meter: '14261'
        },
        {
          rank: "2",
          name: "李四",
          class: 'background-color:red',
          meter: '1254'
        },
        {
          rank: "3",
          name: "李四啊",
          class: 'background-color:yellow',
          meter: '9563'
        },
        {
          rank: "4",
          name: "王二麻子",
          class: 'background-color:green',
          meter: '7568'
        },
        {
          rank: "5",
          name: "赵三",
          class: 'background-color:pink',
          meter: '5426'
        },
      ],
      // allData: [],
      // // tabledata: null,
      // newData: [],
      // indexNum: 0,
      count: 0,
      dayRankData: null,
      top1: null,
      top2: null,
      top3: null,
      config: {
        header: ["头像", "昵称", "里程"],
        data: [],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true,
        indexHeader: '排名',
        columnWidth: [75],
        align: ["center"]
      }
    };
  },
  // watch: {
  //   sumRank: {
  //     handler (newName, oldName) {
  //       if (newName != oldName) {
  //         this.sumRank = newName
  //       }

  //     },
  //   },
  // },









  mounted () {
    // if(this.sumRank){
    //   this.allData = this.sumRank.map((v) => {
    //     let num = (v.sumDistance / this.sumRank[0].sumDistance).toFixed(2) * 100
    //     return {
    //       ...v,
    //       num: `${num}%`
    //     }
    //   })

    //   setInterval(()=>{
    //     if(this.allData.length>6){
    //       this.setTabledata()
    //     }
    //   },5000)
    //   this.allData.map((v, i) => {
    //     if (i < 7) {
    //       this.indexNum = i + 1
    //       this.newData.push({
    //         ...v,
    //       })
    //     }
    //   })
    // }



  },

  methods: {
    // setTabledata () {
    //   this.newData.shift();
    //   this.newData.push(this.allData[this.indexNum])
    //   if (this.indexNum + 1 < this.allData.length) {
    //     this.indexNum = this.indexNum + 1
    //   } else {
    //     this.indexNum = 0
    //   }
    // },

    // getRankList () {
    //   axios
    //     .get('http://192.168.0.184:9999/pad/index/getSumRank')
    //     .then(response => {
    //       let array = []
    //       array.push(response.data.data)
    //       // this.newData=array[0]
    //       this.allData = array[0].map((v) => {
    //         let num = (v.sumDistance / array[0][0].sumDistance).toFixed(2) * 100
    //         return {
    //           ...v,
    //           num: `${num}%`
    //         }
    //       })
    //       if(this.allData.length>6){
    //         setInterval(this.setTabledata, 5000);
    //       }
    //       this.allData.map((v, i) => {
    //         if (i < 7) {
    //           this.indexNum = i + 1
    //           this.newData.push({
    //             ...v,
    //           })
    //         }
    //       })
    //     }
    //     )
    // },

    // getRankListResult (apiresult) {
    //   console.log(apiresult);
    //   if (apiresult.code == 200) {
    //     let data = apiresult.result;
    //     let list = [];
    //     if (data.day.length > 0) {
    //       this.top1 = data.day[0];
    //     } else {
    //       this.top1 = null;
    //     }
    //     if (data.day.length > 1) {
    //       this.top2 = data.day[1];
    //     } else {
    //       this.top2 = null;
    //     }
    //     if (data.day.length > 2) {
    //       this.top3 = data.day[2];
    //     } else {
    //       this.top3 = null;
    //     }
    //     data.day.forEach(item => {
    //       let objarray = [];
    //       // if(item.avatarUrl==null || item.avatarUrl.length==0){
    //       //   objarray.push("<span  class='ant-avatar ant-avatar-circle ant-avatar-image'><img src='../assets/user.png' /></span>");
    //       // }else{
    //       objarray.push("<span  class='ant-avatar ant-avatar-circle ant-avatar-image'><img src='" + item.avatarUrl + "' /></span>");
    //       //}
    //       objarray.push(item.nickName);
    //       let dis = (item.sumDistance / 1000).toFixed(2)
    //       objarray.push("<span  class='colorGrass'>" + dis + "km</span>");
    //       list.push(objarray);
    //     })
    //     let configData = {
    //       header: ["头像", "昵称", "里程"],
    //       data: [],
    //       rowNum: 7, //表格行数
    //       headerHeight: 35,
    //       headerBGC: "#0f1325", //表头
    //       oddRowBGC: "#0f1325", //奇数行
    //       evenRowBGC: "#171c33", //偶数行
    //       index: true,
    //       indexHeader: '#',
    //       columnWidth: [80, 150, 200, 150],
    //       align: ["center"]
    //     };
    //     configData.data = list;
    //     this.config = configData;
    //     console.log("this.config.data" + configData)
    //   } else {
    //     console.log("读取数据失败" + apiresult.message)
    //   }
    // }

  }
};
</script>

<style lang="scss">
@keyframes record {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
#centerRight1 {
  padding: 0.3rem 0.16rem 0 0.16rem;
  height: 100%;
  min-width: 100%;
  border-radius: 0.0625rem;
  .centerBox {
    height: 100%;
    overflow: hidden;
    // border-radius: 0.125rem;
    .centerBox-ul {
      width: 100%;
      height: 14%;
      .centerBox-li {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 3%;
        margin-bottom: 3%;
        // -webkit-animation: record 5s linear infinite;
        // -moz-animation: record 5s linear infinite;
        // -ms-animation: record 5s linear infinite;
        // -o-animation: record 5s linear infinite;
        // animation:record 5s linear infinite;
        .centerBox-li1 {
          width: 10%;
          // background: orange;
          .centerBox-li1-img {
            width: 100%;
            height: 100%;
          }
          .centerBox-li1-no {
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #46a6fb;
            opacity: 0.7;
            font-size: 0.22rem;
            text-align: center;
          }
        }
        .centerBox-li2 {
          width: 25%;
          // background: lawngreen;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          font-size: 0.16rem;
          padding-left: 0.1rem;
        }
        .centerBox-li3 {
          width: 65%;
          height: 75%;
          background: #1257d244;
          // opacity: 0.22;
          border-radius: 2rem;
          position: relative;

          .centerBox-li3-1 {
            height: 99%;
            background: linear-gradient(90deg, #364dca, #5682f5);
            border-radius: 2rem;
            display: flex;
            align-items: center;
            padding-left: 5%;
            font-size: 0.16rem;
          }
          .centerBox-li3-2 {
            height: 99%;
            background: linear-gradient(90deg, #cd1855, #3554b1);
            border-radius: 2rem;
            display: flex;
            align-items: center;
            padding-left: 5%;
            font-size: 0.16rem;
          }
          .centerBox-li3-time {
            position: absolute;
            top: 50%;
            right: 2%;
            transform: translate(-50%, -50%);
            font-size: 0.16rem;
          }
        }
      }
    }
  }
  .text {
    color: #c3cbde;
  }
  .body-box1 {
    border-radius: 0.125rem;
    // overflow: hidden;
    height: 100%;
  }
  .ant-badge-count,
  .ant-badge-dot,
  .ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    top: 45px;
    left: 15px;
  }
  .ant-avatar {
    border: 1px solid #ccc;
  }
}
</style>
