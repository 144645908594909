<template>
  <div id="center">
    <video id="adVideo" class="videoStyle" :src="indexInfo.video" muted loop autoplay="autoplay" ></video>
<!--    <video id="adVideo" class="videoStyle" src="http://localhost:9999/video/ad.mp4" muted loop autoplay="autoplay" ></video>-->
  </div>
</template>

<script>
export default {
  name:'indexInfo',
  data () {
    return {
      //adVideo:null,
    };
  },
  props: {
    indexInfo:Object
  },
  components: {

    // centerChart1,
    // centerChart2
  },
  created() {
    this.$bus.$on('playVideo', function (play) {
      let vide=document.getElementById("adVideo");
      if(play){
        vide.play();
        console.log("开始播放广告")
      }else{
        vide.pause();
        console.log("停止播放广告")
      }
    })
  },
  mounted() {

  },
};
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  // min-width: 90%;
  // padding: 0.2rem;
  justify-content: center;
}
.videoStyle{
  width: 98%;
  height:98%;
}
</style>
